import { useEffect, useRef, useState } from 'react';
import { Input, Textarea, } from "@nextui-org/react";
import { useForm } from 'react-hook-form';

interface IProps {
  fields: IField[];
  onBlur: Function;
  onHover: Function;
  isFocusFirstField?: boolean;
  isResetForm?: boolean
}

const DynamicForm = (props: IProps) => {
  const { fields, onBlur, onHover, isFocusFirstField, isResetForm } = props
  const [formFields, setFormFields] = useState<IField[]>([]);
  const [inputWidth, setInputWidth] = useState('0px');

  const descriptionRef = useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    setFocus,
    formState: { errors },
  } = useForm();
  const formValues = watch();

  useEffect(() => {
    if (descriptionRef.current) {
      const descWidth = descriptionRef.current.offsetWidth;
      setInputWidth(`calc(100% - ${descWidth + 10}px)`);
    }

  }, [descriptionRef, formFields]);

  useEffect(() => {
    formFields.forEach((item) => {
      setValue(item.name, item.value)
    })

    if (isFocusFirstField) setFocus(formFields[0]?.name)
  }, [formFields])

  useEffect(() => {
    if (isResetForm) reset()
    setFormFields(fields)
  }, [fields])

  const handleBlur = () => {
    onBlur(formValues)
  }

  const submit = (data: any) => {

  }

  const handleMouseEnter = (index: number) => {
    onHover(index)
  }

  const handleMouLeave = () => {
    onHover(undefined)
  }

  const renderFormField = (field: IField, index: number) => {
    const fieldValue = formValues[field.name] || '';

    switch (field.type) {
      case 'text':
      case 'number':
        return (
          <Input
            classNames={{
              description: ["text-black40", "text-base", "font-normal"],
              helperWrapper: ["absolute", "right-3", "bottom-2/4", "translate-y-2/4"],
              mainWrapper: ["relative"],
              inputWrapper: [
                "border",
                fieldValue
                  ? field.confidence === 'HIGH'
                    ? "border-green-500"
                    : "border-yellow-500"
                  : "border-red-500",
              ],
              label: ["text-[#52525B]", "text-xs", "top-5"],
              innerWrapper: ["text-base", "text-[#11181C]"]
            }}
            variant='bordered'
            radius="sm"
            labelPlacement="outside"
            size="lg"
            color={!fieldValue ? 'danger' : 'default'}
            style={{ width: inputWidth }}
            className={`bg-white`}
            description={<div ref={descriptionRef}>{!fieldValue ? 'No data extracted' : ''}</div>}
            type={field.type}
            label={field.label}
            value={fieldValue}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouLeave}
            {...register(field.name)}
          />
        );
      case 'textarea':
        return (
          <Textarea
            labelPlacement="outside"
            startContent={<></>}
            label={field.label}
            value={fieldValue}
            {...register(field.name)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)} onBlur={handleBlur} data-testid="dynamic-form" className="flex flex-col mb-3 space-y-4 flex-grow pr-1 scrollbar-custom overflow-y-auto">
        {formFields.map((field, index) => (
          <div key={index} className="w-full">
            {renderFormField(field, index)}
          </div>
        ))}
      </form>
    </>
  );
};

export default DynamicForm;
