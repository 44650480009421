import { Button } from "@nextui-org/react";
import DynamicForm from "../DynamicForm/DynamicForm"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { STAGE_TITLE } from "@/constants/constants";

interface IProps {
  inputTypeName: string;
  fields: IField[];
  getFormValues: Function;
  getHoveredIndex: Function;
  acceptedValue: IFileResult[];
  onProceed: Function
}
function DataExtractionWrapper(props: IProps) {
  const { fields, getFormValues, getHoveredIndex, acceptedValue, onProceed } = props;
  const [formValues, setFormValues] = useState<any>({})

  useEffect(() => {
    getFormValues(formValues)
  }, [formValues])

  const handleProceed = () => {
    onProceed()
  }

  const handleBlur = (formValues: {[key: string]: string}) => {
    setFormValues(formValues)
  }

  return (
    <div className="bg-customWhite-light h-full p-4 flex flex-col">
      <div className="flex items-center text-black text-base font-semibold mb-6 shrink-0">
        <div className="mr-1">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
          </svg>
        </div>
        <span>{STAGE_TITLE.SECOND}</span>
      </div>
      <div className="mb-6 text-customGray-dark text-base font-semibold shrink-0 flex flex-col sm:flex-row sm:items-center sm:justify-between pr-4 gap-4">
        <div className="flex items-center gap-2">
          Input type: {props.inputTypeName}
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-4">
          <span className="text-base font-medium whitespace-nowrap">Confidence Meter:</span>
          <div className="flex flex-wrap items-center gap-4">
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 sm:w-4 sm:h-4 rounded-full bg-red-500"></div>
              <span className="text-sm sm:text-base">Missing</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 sm:w-4 sm:h-4 rounded-full bg-yellow-500"></div>
              <span className="text-sm sm:text-base">Check</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 sm:w-4 sm:h-4 rounded-full bg-green-500"></div>
              <span className="text-sm sm:text-base">Good</span>
            </div>
          </div>
        </div>
      </div>

      <DynamicForm fields={fields} onBlur={handleBlur} onHover={getHoveredIndex} isFocusFirstField={true} isResetForm={true} />

      <Button radius='sm' type="submit" color="primary" className='shrink-0 w-fit py-3 px-6 h-12' onClick={handleProceed}>
        Proceed
      </Button>
    </div>
  )
}

export default DataExtractionWrapper