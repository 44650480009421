import React, { createContext, useMemo, useState } from 'react';

interface GlobalContextType {
  globalData: {
    hasUnsavedData: boolean
  };
  setGlobalData: React.Dispatch<
    React.SetStateAction<{
      hasUnsavedData: boolean
    }>
  >;
}

const defaultValue: GlobalContextType = {
  globalData: { hasUnsavedData: false },
  setGlobalData: () => {},
};

export const GlobalContext = createContext<GlobalContextType>(defaultValue);

export const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [globalData, setGlobalData] = useState(defaultValue.globalData);

  const contextValue = useMemo(
    () => ({
      globalData,
      setGlobalData,
    }),
    [globalData]
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};