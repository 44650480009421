import axios from "./base.api"

const getWorkflows = async (model: IModelFilters): Promise<ITableData> => {
  const response = await axios.post("/all-file/workflow/list", model)
  return response.data
}

const getFiles = async (model: IModelFilters): Promise<ITableData> => {
  const response = await axios.post("/all-file/file/list", model)
  return response.data
}

const getQuantity = async (): Promise<IQuantity> => {
  const response = await axios.get("/all-file/file/quantity")
  return response.data
}

const getAutocomplete = async (keyword: string): Promise<IAutocompleteData[]> => {
  const response = await axios.get(`/all-file/workflow/autocomplete?keyword=${keyword}`)
  return response.data
}

const getAutocompleteAllFile = async (keyword: string): Promise<IAutocompleteData[]> => {
  const response = await axios.get(`/all-file/file/autocomplete?keyword=${keyword}`)
  return response.data
}

const exportWorkflow = async (
  extraction_ids: string[],
): Promise<{ data: Blob; fileName: string }> => {
  const response = await axios.post(
    "/all-file/workflow/export",
    { extraction_ids },
    {
      responseType: "blob",
    },
  )

  const contentDisposition = response?.headers["content-disposition"]
  const filenameRegex = /filename=(.*)/i
  const match = contentDisposition.match(filenameRegex)
  const fileName = match ? match[1] : ""

  return {
    data: response.data,
    fileName: fileName,
  }
}

const exportAllFile = async (
  model: IExportFilesModel,
): Promise<{ data: Blob; fileName: string }> => {
  const response = await axios.post("/all-file/file/export", model, {
    responseType: "blob",
  })

  const contentDisposition = response?.headers["content-disposition"]
  const filenameRegex = /filename=(.*)/i
  const match = contentDisposition.match(filenameRegex)
  const fileName = match ? match[1] : ""

  return {
    data: response.data,
    fileName: fileName,
  }
}

const getTenantList = async (): Promise<{ id: number; name: string }[]> => {
  const response = await axios.get("/tenant")
  return response.data
}

const deleteExtraction = async (model: {extraction_ids : string[]}): Promise<{message: string}> => {
  const response = await axios.delete("/extraction", {data: model})
  return response.data
}

export {
  getWorkflows,
  getFiles,
  getAutocomplete,
  exportWorkflow,
  getQuantity,
  getAutocompleteAllFile,
  exportAllFile,
  getTenantList,
  deleteExtraction,
}
