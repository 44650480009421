import React, { useRef, useEffect, useState } from "react"
import { ExtractionResult } from "../../types"
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react"
import { IHighLightAreas } from "@/types/pdf-file-keywords"
import RotateLeftIcon from '@assets/images/icons/rotate-left.svg'
import RotateRightIcon from '@assets/images/icons/rotate-right.svg'

interface ImageViewerProps {
  file: File | null
  extractedData?: ExtractionResult[]
  hoveredIndex?: number | null
  highlightAreas: IHighLightAreas[]
}

const ImageViewer: React.FC<ImageViewerProps> = ({
  file,
  extractedData,
  hoveredIndex,
  highlightAreas,
}) => {
  const scaleDefault = 1
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [scale, setScale] = useState(scaleDefault)
  const [panOffset, setPanOffset] = useState({ x: 0, y: 0 })
  const [image, setImage] = useState<HTMLImageElement | null>(null)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)

  useEffect(() => {
    if (file) {
      const img = new Image()
      img.src = URL.createObjectURL(file)
      img.onload = () => {
        setImage(img)
      }
    }
  }, [file])

  useEffect(() => {
    if (image) {
      drawImage()
    }
  }, [image, extractedData, hoveredIndex, scale, panOffset, isFullscreen, rotation])

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement)
    }

    document.addEventListener("fullscreenchange", handleFullscreenChange)
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange)
    }
  }, [])

  const setupCanvas = (canvas: HTMLCanvasElement | null, container: HTMLDivElement) => {
    if (!canvas || !container) return null

    const ctx = canvas.getContext("2d")
    if (!ctx) return null

    const dpr = window.devicePixelRatio || 1
    canvas.width = container.clientWidth * dpr
    canvas.height = container.clientHeight * dpr
    canvas.style.width = `${container.clientWidth}px`
    canvas.style.height = `${container.clientHeight}px`

    ctx.scale(dpr, dpr)
    return ctx
  }

  const calculateDrawDimensions = (container: HTMLDivElement, image: HTMLImageElement) => {
    const containerAspectRatio = container.clientWidth / container.clientHeight
    const imageAspectRatio = image.width / image.height

    let drawWidth, drawHeight
    if (containerAspectRatio > imageAspectRatio) {
      drawHeight = container.clientHeight
      drawWidth = drawHeight * imageAspectRatio
    } else {
      drawWidth = container.clientWidth
      drawHeight = drawWidth / imageAspectRatio
    }

    return { drawWidth, drawHeight }
  }

  const calculateScaledDimensions = (
    container: HTMLDivElement,
    drawWidth: number,
    drawHeight: number,
    scale: number,
    panOffset: { x: number; y: number },
  ) => {
    const scaledWidth = drawWidth * scale
    const scaledHeight = drawHeight * scale
    const offsetX = (container.clientWidth - scaledWidth) / 2 + panOffset.x
    const offsetY = (container.clientHeight - scaledHeight) / 2 + panOffset.y

    return { scaledWidth, scaledHeight, offsetX, offsetY }
  }

  const drawMainImage = (
    ctx: CanvasRenderingContext2D,
    image: HTMLImageElement,
    container: HTMLDivElement,
    dimensions: {
      offsetX: number
      offsetY: number
      scaledWidth: number
      scaledHeight: number
      scale: number
    },
  ) => {
    const { scaledWidth, scaledHeight, scale } = dimensions
  
    ctx.clearRect(0, 0, container.clientWidth, container.clientHeight)
    ctx.save()
    ctx.translate(container.clientWidth / 2, container.clientHeight / 2)
    ctx.rotate((rotation * Math.PI) / 180)
    ctx.imageSmoothingEnabled = scale <= 1
    ctx.imageSmoothingQuality = "high"
    ctx.drawImage(
      image, 
      -scaledWidth / 2 + (panOffset.x * Math.cos((rotation * Math.PI) / 180)), 
      -scaledHeight / 2 + (panOffset.y * Math.cos((rotation * Math.PI) / 180)), 
      scaledWidth, 
      scaledHeight
    )
    ctx.restore()
  }

  const drawHighlightAreas = (
    ctx: CanvasRenderingContext2D,
    container: HTMLDivElement,
    highlightAreas: IHighLightAreas[],
    dimensions: {
      offsetX: number
      offsetY: number
      scaledWidth: number
      scaledHeight: number
      scale: number
    },
    hoveredIndex: number | null | undefined,
  ) => {
    const { scaledWidth, scaledHeight, scale } = dimensions
  
    ctx.save()
    ctx.translate(container.clientWidth / 2, container.clientHeight / 2)
    ctx.rotate((rotation * Math.PI) / 180)
  
    highlightAreas?.forEach((item, index) => {
      const { left, top, width, height } = item
  
      ctx.strokeStyle = index === hoveredIndex ? "yellow" : "red"
      ctx.lineWidth = 1 / scale
      ctx.strokeRect(
        -scaledWidth / 2 + left * scaledWidth + (panOffset.x * Math.cos((rotation * Math.PI) / 180)),
        -scaledHeight / 2 + top * scaledHeight + (panOffset.y * Math.cos((rotation * Math.PI) / 180)),
        width * scaledWidth,
        height * scaledHeight,
      )
  
      if (index === hoveredIndex) {
        ctx.fillStyle = "rgba(255, 255, 0, 0.3)"
        ctx.fillRect(
          -scaledWidth / 2 + left * scaledWidth + (panOffset.x * Math.cos((rotation * Math.PI) / 180)),
          -scaledHeight / 2 + top * scaledHeight + (panOffset.y * Math.cos((rotation * Math.PI) / 180)),
          width * scaledWidth,
          height * scaledHeight,
        )
      }
    })
  
    ctx.restore()
  }

  const drawImage = () => {
    const canvas = canvasRef.current
    const container = containerRef.current
    if (!canvas || !container || !image) return

    const ctx = setupCanvas(canvas, container)
    if (!ctx) return

    const { drawWidth, drawHeight } = calculateDrawDimensions(container, image)

    const dimensions = calculateScaledDimensions(container, drawWidth, drawHeight, scale, panOffset)

    drawMainImage(ctx, image, container, { ...dimensions, scale })
    drawHighlightAreas(ctx, container, highlightAreas, { ...dimensions, scale }, hoveredIndex)
  }

  const handleRotate = (direction: 'left' | 'right') => {
    setRotation(prev => {
      const delta = direction === 'left' ? -90 : 90
      return (prev + delta + 360) % 360
    })
  }

  const handleZoom = (delta: number) => {
    setScale(prevScale => {
      const newScale = Math.max(0.1, Math.min(prevScale + delta, 10))
      return newScale
    })
  }

  const resetView = () => {
    setScale(scaleDefault)
    setPanOffset({ x: 0, y: 0 })
  }

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current?.requestFullscreen()
    } else {
      document.exitFullscreen()
    }
    setTimeout(() => {
      resetView()
    }, 200)
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    setIsDragging(true)
    setDragStart({ x: e.clientX - panOffset.x, y: e.clientY - panOffset.y })
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (isDragging) {
      const dx = e.clientX - dragStart.x
      const dy = e.clientY - dragStart.y
      setPanOffset({ x: dx, y: dy })
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const zoomOptions = [0.5, 0.75, 1, 1.25, 1.5, 2].map(value => ({
    key: value.toString(),
    label: `${value * 100}%`,
  }))

  const handleZoomSelect = (key: string) => {
    setScale(parseFloat(key))
  }

  return (
    <div
      ref={containerRef}
      className="border-1 border-customGray-light rounded-lg relative overflow-hidden w-full h-full"
    >
      <canvas
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          cursor: isDragging ? "grabbing" : "grab",
        }}
        className="bg-white"
        data-testid="image-viewer-canvas"
      />
      <div className="border-b-1 border-customGray-light bg-white absolute top-0 left-0 px-3 w-full flex items-center justify-between">
        <span className="max-w-52 whitespace-nowrap overflow-hidden text-ellipsis">
          {file?.name}
        </span>

        <div className="flex items-center gap-2">
          <div className="flex items-center gap-1">
            <Button
              isIconOnly
              color="default"
              variant="light"
              onClick={() => handleZoom(0.1)}
              style={{ marginRight: "5px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6"
                />
              </svg>
            </Button>
            <Dropdown classNames={{ content: ["min-w-[100px]"] }}>
              <DropdownTrigger>
                <Button size="sm" variant="bordered">
                  {`${Math.round(scale * 100)}%`}
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Zoom levels"
                onAction={key => handleZoomSelect(key as string)}
                selectedKeys={[scale.toString()]}
                selectionMode="single"
              >
                {zoomOptions.map(option => (
                  <DropdownItem key={option.key}>{option.label}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Button
              isIconOnly
              color="default"
              variant="light"
              onClick={() => handleZoom(-0.1)}
              style={{ marginRight: "5px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM13.5 10.5h-6"
                />
              </svg>
            </Button>
          </div>

          <div className="flex items-center">
            <Button isIconOnly aria-label="rotate-left"  variant='light' onClick={() => handleRotate('left')}>
              <img src={RotateLeftIcon} alt="icon rotate" className="size-6"/>
            </Button>
            <Button isIconOnly aria-label="rotate-right"  variant='light' onClick={() => handleRotate('right')}>
              <img src={RotateRightIcon} alt="icon rotate" className="size-6"/>
            </Button>
          </div>
        </div>

        <Button isIconOnly color="default" variant="light" data-testid="fullscreen-button" onClick={toggleFullscreen}>
          {isFullscreen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 9V4.5M9 9H4.5M9 9 3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5 5.25 5.25"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
              />
            </svg>
          )}
        </Button>
      </div>
    </div>
  )
}

export default ImageViewer