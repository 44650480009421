import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react"
import { ReactElement, useEffect } from "react"

interface IProps {
  isOpenModal: boolean
  title: string
  content: string | ReactElement
  onOpenModalChange: Function
  onConfirm: Function
  confirmButton?: IButton
  closeButton?: IButton
}

interface IButton {
  title?: string
  bgColor?: string
}

function ConfirmModal(props: IProps) {
  const {
    isOpenModal,
    title,
    content,
    onConfirm,
    onOpenModalChange,
    confirmButton,
    closeButton,
  } = props
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()

  useEffect(() => {
    if (isOpenModal) onOpen()
  }, [isOpenModal])

  useEffect(() => {
    onOpenModalChange(isOpen)
  }, [isOpen])

  const confirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange} isDismissable={false}>
      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">{title}</ModalHeader>
            <ModalBody>
              {content}
              <div className="border-b-1 border-black40 w-full px-6"></div>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="solid"
                radius="sm"
                className={`bg-customGray-medium text-white h-12 py-3 px-6 ${closeButton?.bgColor || ""}`}
                onPress={onClose}
              >
                {closeButton?.title ?? 'Close'}
              </Button>
              <Button
                color="primary"
                radius="sm"
                className={`h-12 py-3 px-6 ${confirmButton?.bgColor || ""}`}
                onPress={confirm}
              >
                {confirmButton?.title ?? 'Proceed'}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ConfirmModal
