import ExtractLayout from "@/layouts/ExtractLayout/ExtractLayout"
import ListFile from "../ListFile/ListFile"
import DynamicForm from "../DynamicForm/DynamicForm"
import { Button, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react"
import FirstStepHeader from "../FirstStepHeader/FirstStepHeader"
import SearchableDropdown from "../SearchableDropdown/SearchableDropdown"
import { useEffect, useState } from "react"
import { FILE_TYPES, LIMIT_VERTICAL_LIST, MODE, PERMISSIONS, STAGE_TITLE, URL_PARAMS } from "@/constants/constants"
import { PdfViewer } from "../PdfViewer/PdfViewer"
import { downloadOutputFile, getExtractionResult } from "@/apis/extract.api"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import ImageViewer from "../ImageViewer/ImageViewer"
import { IHighLightAreas } from "@/types/pdf-file-keywords"
import * as _ from 'lodash';
import { fetchImage, handleError, prepareDataHighlight, renderItems, showWarningFile } from "@/constants/common"
import Loader from "../Loader/Loader"
import { showError, showSuccess } from "@/helpers/toastify"
import { saveAs } from "file-saver"
import { toast } from "react-toastify"
import { setLocalItem } from "@/helpers/storage"
import usePermissions from "@/hooks/usePermissions/usePermissions"
import { PATH } from "@/constants/paths"
import { COOKIES } from "@/constants/cookies"
import WarningIcon from "@assets/images/icons/warning.svg"

const OutputPreview = () => {
  const [mode, setMode] = useState(MODE.DATA_EXTRACTION)
  const [files, setFiles] = useState<IFile[]>([])
  const [filesOuput, setFilesOuput] = useState<IFile[]>([])

  const [fileSelected, setFileSelected] = useState<IFile>()
  const [fields, setFields] = useState<IField[]>([])
  const [fileId, setFileId] = useState<string>('')
  const [extractData, setExtractData] = useState<IExtractionResult>({ extraction_id: '', files: [], description: '' })
  const [acceptedValue, setAcceptedValue] = useState<IFileResult[]>([])
  const [image, setImage] = useState<File | null>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [inputTypeName, setInputTypeName] = useState<string>('')

  const navigate = useNavigate()
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const extractionId = searchParams.get(URL_PARAMS.EXTRACTION_ID) || '';
  const outputId = URL_PARAMS.OUTPUT_ID
  const { hasPermission, isLoadingPermissions } = usePermissions()

  const failedFiles = files.filter((item) => item.isWarning) || [];

  useEffect(() => {
    if (!isLoadingPermissions && !hasPermission(PERMISSIONS.PREVIEW_OUTPUT)) {
      navigate(PATH.FORBIDDEN)
    }
  }, [isLoadingPermissions])

  useEffect(() => {
    return () => {
      toast.dismiss()
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await getExtractionResult(extractionId);
        setLocalItem(COOKIES.WORKFLOW_NAME, data.description);
        setExtractData(data);
      } catch (error) {
        showError(handleError(error));
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [extractionId]);

  useEffect(() => {
    const extractedFiles = _.cloneDeep(extractData.files)
    setAcceptedValue(extractedFiles)

    const ouputs = extractData.generated_output?.map((item) => (
      { fileUrl: item.s3_presigned_url, fileType: item.file_type, fileName: item.file_name, isWarning: false, highlightAreas: [], id: item.id }
    )) || []
    setFilesOuput(ouputs)
  }, [extractData])

  useEffect(() => {
    getData()
  }, [acceptedValue])

  useEffect(() => {
    const file = files.find((item) => item.id === fileId)
    setFileSelected(file)
  }, [fileId])

  useEffect(() => {
    const loadImage = async () => {
      if (fileSelected) {
        const fetchedImage = await fetchImage(fileSelected);
        setImage(fetchedImage);
      } else {
        setImage(null);
      }
    };

    loadImage();
  }, [fileSelected]);

  const handleFilesSelected = (id: string) => {
    toast.dismiss()
    setFileId(id)
    updateParams(id, false)
    setFields([])
    getData()

    setTimeout(() => {
      generateFieldsAndInputTypeName(id)
    }, 0)
  }

  const handleFilesOuputSelected = (id: string) => {
    setFileId(id)
    updateParams(id, true)
  }

  const handleBlur = (formValues: { [key: string]: string }) => {
    const file = acceptedValue.find((file) => file.id === fileId);
    if (!file) return;

    const extractionResult = file.extraction_result;
    const updatedResult = extractionResult.map((item) => {
      const fieldAlias = item.field_alias;
      if (formValues.hasOwnProperty(fieldAlias)) {
        return { ...item, user_accepted_value: formValues[fieldAlias] };
      }
      return item;
    });

    file.extraction_result = updatedResult;
    setAcceptedValue([...acceptedValue]);

    const fieldsUpdated = fields.map((item) => {
      if (formValues.hasOwnProperty(item.name)) {
        return { ...item, value: formValues[item.name] };
      }
      return item;
    })

    setFields(fieldsUpdated)
  }

  const handleSwitchMode = () => {
    setMode(mode === MODE.DATA_EXTRACTION ? MODE.VIEW_FILE : MODE.DATA_EXTRACTION)
  }

  const getData = () => {
    const data = prepareDataHighlight(acceptedValue, 'user_accepted_value')
    setFiles(data)
  }

  const generateFieldsAndInputTypeName = (id?: string) => {
    const idx = acceptedValue.findIndex((file) => file.id === id)
    if (idx === -1) return

    const formFields = acceptedValue[idx].extraction_result.map((item) => {
      return {
        type: 'text',
        label: item.field_name,
        value: item.user_accepted_value,
        name: item.field_alias,
        confidence: item.confidence
      }
    })
    setInputTypeName(acceptedValue[idx].input_type_name ?? '')
    setFields([...formFields])
  }

  const getHighLightAreas = (): IHighLightAreas[] => {
    return files.find((item) => item.id === fileSelected?.id)?.highlightAreas || []
  }

  const updateParams = (id: string, isOutput: boolean) => {
    const params = new URLSearchParams(location.search);
    if (extractionId) params.set(URL_PARAMS.EXTRACTION_ID, extractionId);

    const paramType = isOutput ? URL_PARAMS.OUTPUT_ID : URL_PARAMS.FILE_ID;
    if (id) {
      params.set(paramType, id);
    }
    navigate(`${location.pathname}?${params.toString()}`);
  }

  const handleDownloadOuputFile = async () => {
    try {
      const data = await downloadOutputFile(extractionId)
      saveAs(data.download_url)
      showSuccess('Download is successful')
    } catch (error: any) {
      const msg = handleError(error)
      showError(msg)
    }
  }

  const renderEmptyFieldsWarning = () => {
    const valueKey = 'user_accepted_value';
    const selectedFile = acceptedValue.find(item => item.id === fileId);
  
    if (!selectedFile) return null;
  
    const emptyFieldsData = selectedFile.extraction_result
      .filter(item => !item[valueKey])
      .map(({ field_name, [valueKey]: value }) => ({ field_name, [valueKey]: value }));
  
    if (emptyFieldsData.length === 0) return null;
  
    return (
      <div className="px-4 py-4 bg-[#FFF4E5] text-[#663C00] text-base font-medium flex items-center gap-2 mb-6 rounded-md">
        <img src={WarningIcon} alt="warning icon" className="h-6" />
        <div>
          <div>
            {selectedFile.original_file_name} has {emptyFieldsData.length} empty field(s): {renderItems(emptyFieldsData)}.
          </div>
          <div>This file won't be included in the output.</div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading && <Loader title="Preparing Output Files in Progress …" />}
      <ExtractLayout
        leftComponent={
          <div className="h-full flex flex-col">
            <ListFile
              onFilesSelectedChange={handleFilesSelected}
              hideCloseIcon={true}
              files={files}
              hideFileViewer={true}
              hideContent={true}
              headerElement={<FirstStepHeader files={files} />}
            />

            <div className="border-b border-customWhite-medium"></div>

            <div className="h-full flex flex-col overflow-hidden p-4 bg-customWhite-light">
              <div className="flex items-center text-black text-base font-semibold mb-6 shrink-0">
                <div className="mr-1">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                  </svg>
                </div>
                <span>{STAGE_TITLE.SECOND}</span>
                <Button className="ml-auto"
                  color="default"
                  variant="light"
                  onClick={handleSwitchMode}
                  endContent={
                    mode === MODE.DATA_EXTRACTION ? (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-primary">
                        <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                        <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-primary">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                      </svg>
                    )
                  }>
                  {mode === MODE.DATA_EXTRACTION ? 'View file' : 'View data'}
                </Button>
              </div>

              {mode === MODE.DATA_EXTRACTION && (
                <>
                  <div className="mb-2 text-customGray-dark text-base font-semibold ">Input type: {inputTypeName}</div>
                  {renderEmptyFieldsWarning()}
                  <DynamicForm fields={fields} onBlur={handleBlur} onHover={setHoveredIndex} />
                </>
              )}

              {mode === MODE.VIEW_FILE && (
                <div className="h-full flex-grow overflow-y-auto fade">
                  {FILE_TYPES.PDF.includes(fileSelected?.fileType || '') && (
                    <div className="h-full flex-grow overflow-y-auto">
                      <PdfViewer fileKeywords={{ fileUrl: fileSelected?.fileUrl || '', keywords: [''], fileName: fileSelected?.fileName }} highlightAreas={getHighLightAreas()} hoveredIndex={hoveredIndex} />
                    </div>
                  )}

                  {FILE_TYPES.IMAGE.includes(fileSelected?.fileType || '') && (
                    <ImageViewer file={image} highlightAreas={getHighLightAreas()} hoveredIndex={hoveredIndex} />
                  )}
                </div>
              )}
            </div>
          </div>
        }

        rightComponent={
          <div className="bg-white h-full flex flex-col">
            <ListFile
              onFilesSelectedChange={handleFilesOuputSelected}
              hideCloseIcon={true}
              hideFileViewer={false}
              files={filesOuput}
              paramName={outputId}
              hasOutput={true}
              onDownloadOuputFile={handleDownloadOuputFile}
              headerElement={
                <div className="flex items-center text-black text-base font-semibold mb-6 shrink-0">
                  <div className="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>
                  </div>
                  <span>{STAGE_TITLE.THRIRD}</span>
                  <div className="ml-auto flex">
                    <div className="font-medium text-sm">
                      <span className="mr-3">Txns Success: {files.length - failedFiles.length}</span>
                      {failedFiles.length > 0 ? (
                        <Popover placement="bottom-end" showArrow>
                          <PopoverTrigger>
                            <span className="underline text-primary cursor-pointer">
                              Failed: {failedFiles.length}
                            </span>
                          </PopoverTrigger>
                          <PopoverContent>
                            <div className="px-1 py-2 flex flex-col items-start gap-2">
                              {failedFiles.map((file) => (
                                <button
                                  className="block underline text-primary"
                                  key={file.id}
                                  onClick={() => navigate(`${location.pathname}?extractionId=${extractionId}&fileId=${file.id}&ouputId=${outputId}`)}
                                >
                                  {file.fileName}
                                </button>
                              ))}
                            </div>
                          </PopoverContent>
                        </Popover>
                      ) : (
                        <span className="underline text-primary cursor-pointer">
                          Failed: {failedFiles.length}
                        </span>
                      )}
                    </div>
                    { filesOuput.length >= LIMIT_VERTICAL_LIST && 
                      <div className="h-auto border-l-1 border-gray mx-3"></div>
                    }
                    <div className="cursor-pointer">
                      <SearchableDropdown items={filesOuput} title={filesOuput.length >= LIMIT_VERTICAL_LIST ? `Show ${filesOuput.length} file(s)` : ''} paramName={outputId} />
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        } />
    </>
  )
}

export default OutputPreview
