import FilterFiles from "../FilterFiles/FilterFiles"
import TableCustom from "../TableCustom/TableCustom"
import { useCallback, useEffect, useRef, useState, Fragment } from "react"
import RenameWorkflow from "../RenameWorkflow/RenameWorkflow"
import {
  ALL_FILES_MODE,
  CHANNEL,
  ICON_TYPES,
  STAGE,
  STATUS_DISPLAY,
  STATUS_KEYS,
  URL_PARAMS,
} from "@/constants/constants"
import { Link, useSearchParams } from "react-router-dom"
import { renameWorkflow } from "@/apis/extract.api"
import { showError, showSuccess } from "@/helpers/toastify"
import { formatDateTime, handleError } from "@/constants/common"
import {
  deleteExtraction,
  exportAllFile,
  exportWorkflow,
  getAutocomplete,
  getAutocompleteAllFile,
  getFiles,
  getQuantity,
  getWorkflows,
} from "@/apis/all-files.api"
import FileSaver from "file-saver"
import _ from "lodash"
import { Divider, Tooltip } from "@nextui-org/react"

// icons
import ComputerIcon from "@assets/images/icons/computer.svg"
import SFTPIcon from "@assets/images/icons/sftp.svg"
import EmailIcon from "@assets/images/icons/email.svg"
import PdfIcon from "@/assets/images/icons/pdf-file.svg"
import JpgIcon from "@/assets/images/icons/jpg-file.svg"
import PngIcon from "@/assets/images/icons/png-file.svg"
import TxtIcon from "@/assets/images/icons/txt-file.svg"
import useLocalStorage from "@/hooks/useLocalStorage/useLocalStorageWithEvent"
import ConfirmModal from "../ConfirmModal/ConfirmModal"
import { COOKIES } from "@/constants/cookies"
import { CalendarDateTime } from "@internationalized/date"

const WORKFLOW_COLUMNS = [
  {
    name: "Channel",
    uid: "channel",
    sortable: true,
  },
  {
    name: "Workflow ID",
    uid: "extraction_id",
  },
  {
    name: "Workflow Name",
    uid: "description",
    sortable: true,
  },
  {
    name: "Status",
    uid: "status",
  },
  {
    name: "Input Files",
    uid: "total_input_file",
  },
  {
    name: "Output Files",
    uid: "total_output_file",
  },
  {
    name: "Successful Txns",
    uid: "total_input_success",
  },
  {
    name: "Failed Inputs",
    uid: "total_input_failed", 
  },
  {
    name: "Created",
    uid: "created_at",
    sortable: true,
  },
  {
    name: "Created by",
    uid: "created_by",
  },
  {
    name: "Last Modified",
    uid: "updated_at",
    sortable: true,
  },
  {
    name: "Modified by",
    uid: "updated_by",
  },
]

const FILE_COLUMNS = [
  {
    name: "Channel",
    uid: "channel",
    sortable: true,
  },
  {
    name: "File Name",
    uid: "file_name",
    sortable: true,
  },
  {
    name: "I/O",
    uid: "process_type",
    sortable: true,
  },
  {
    name: "File Size",
    uid: "file_size",
    sortable: true,
  },
  {
    name: "Workflow ID",
    uid: "extraction_id",
  },
  {
    name: "Successful Txns",
    uid: "transaction_success",
    sortable: true,
  },
  {
    name: "Extracted",
    uid: "extracted",
  },
  {
    name: "Updated",
    uid: "updated_at",
    sortable: true,
  },
  {
    name: "Updated by",
    uid: "updated_by",
  },
  {
    name: "Status",
    uid: "status",
  },
]

const defaultData = {
  data: [],
  items_per_page: 50,
  page: 1,
  total_items: 0,
}

const iconMap = {
  [ICON_TYPES.PDF]: PdfIcon,
  [ICON_TYPES.JPG]: JpgIcon,
  [ICON_TYPES.PNG]: PngIcon,
  [ICON_TYPES.JPEG]: JpgIcon,
  [ICON_TYPES.TXT]: TxtIcon,
}

const AllFilesWrapper = () => {
  const [searchParams] = useSearchParams()
  const mode = searchParams.get(URL_PARAMS.MODE)

  const [isLoading, setIsLoading] = useState(false)
  const [autocompleteData, setAutocompleteData] = useState<IAutocompleteData[]>([])
  const [currentColumns, setCurrentColumns] = useState(WORKFLOW_COLUMNS)
  const [currentData, setCurrentData] = useState<ITableData>(defaultData)
  const [rowsSelected, setRowsSelected] = useState<string[]>([])
  const [resetSelection, setResetSelection] = useState(false)
  const [quantity, setQuantity] = useState<IQuantity>()
  const [modelFilters, setModelFilters] = useState<IModelFilters>({
    keyword: "",
    end_date: null,
    items_per_page: 50,
    page: 1,
    sort_field: "",
    sort_direction: "",
    start_date: null,
    attribute: "",
    show_deleted: false,
  })
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  const prevModelFiltersRef = useRef(modelFilters)
  const initialized = useRef(false);
  const [organizationId] = useLocalStorage(COOKIES.ORGANIZATION_ID)

  useEffect(() => {
    if (mode === ALL_FILES_MODE.FILES) {
      getTotalQuantity();
    }
  }, []);

  useEffect(() => {
    if (!_.isEqual(modelFilters, prevModelFiltersRef.current)) {      
      fetchData(mode)
      prevModelFiltersRef.current = modelFilters
    }
  }, [modelFilters])

  useEffect(() => {
    if (!mode) return;
  
    if (!initialized.current) {
      initialized.current = true;
      return;
    }
  
    if (mode === ALL_FILES_MODE.FILES) {
      getTotalQuantity();
    }
    fetchData(mode);
    fetchAutocomplete();
  }, [mode, organizationId]);

  const handleRenameWorkflow = async (value: string, itemId: string) => {
    try {
      const data = await renameWorkflow(itemId, value)
      showSuccess(data.message)
      fetchData(mode ?? ALL_FILES_MODE.WORKFLOWS)
    } catch (error) {
      showError(handleError(error))
    }
  }

  const fetchData = async (modeParam = mode) => {
    const effectiveMode = modeParam ?? ALL_FILES_MODE.WORKFLOWS
    if (!effectiveMode) return
    setIsLoading(true)

    try {
      const isWorkflowMode = effectiveMode === ALL_FILES_MODE.WORKFLOWS
      const data = isWorkflowMode 
        ? await getWorkflows(modelFilters) 
        : await getFiles(modelFilters)
      setCurrentColumns(isWorkflowMode ? WORKFLOW_COLUMNS : FILE_COLUMNS)
      setCurrentData(data)
    } catch (error) {
      showError(handleError(error))
      setCurrentData(defaultData)
    } finally {
      setIsLoading(false)
    }
  }

  const getChannelIcon = (channel: string) => {
    const icons = {
      [CHANNEL.PLATFORM]: ComputerIcon,
      [CHANNEL.SFTP]: SFTPIcon,
      [CHANNEL.EMAIL]: EmailIcon,
    }
    return icons[channel as keyof typeof icons]
  }

  const isAllFilesResponse = (data: any): data is IAllFilesResponse => {
    return (
      data && typeof data === "object" && "file_type" in data && typeof data.file_type === "string"
    )
  }

  const customRenderCell = useCallback(
    (data: IAllFilesResponse | IWorkflowResponse, columnKey: string) => {
      const cellValue = data[columnKey as keyof typeof data]
      const status = data["status" as keyof typeof data]

      switch (columnKey) {
        case "channel":
          const icon = getChannelIcon(cellValue as string)
          return (
            icon && (
              <div className="flex justify-center group-data-[disabled=true]:opacity-50">
                <img src={icon} alt={`${cellValue} icon`} />
              </div>
            )
          )
        case "file_name":
          if (isAllFilesResponse(data)) {
            return (
              <div className="flex whitespace-nowrap gap-1">
                <img src={iconMap[data.file_type]} alt="icon" />
                <span>{cellValue}</span>
              </div>
            )
          }
          return null
        case "extraction_id": {
          const current_state = data["current_state"] as StateKeys
          const url = `${STAGE[current_state]}?${URL_PARAMS.EXTRACTION_ID}=${data["extraction_id"]}`

          return (
            <div className="flex whitespace-nowrap">
              <Link
                className={`cursor-pointer ${status !== STATUS_KEYS.DELETED ? "text-primary underline" : "pointer-events-none select-none"}`}
                to={url}
                onClick={(e) => status === STATUS_KEYS.DELETED && e.preventDefault()}
              >
                <div className="max-w-[200px] truncate">{cellValue}</div>
              </Link>
            </div>
          )
        }
        case "status":
          return (
            <div className="whitespace-nowrap">
              {STATUS_DISPLAY[cellValue as keyof typeof STATUS_DISPLAY]}
            </div>
          )
        case "created_at":
        case "updated_at":
          return <div className="whitespace-nowrap">{formatDateTime(cellValue)}</div>
        case "total_input_file":
        case "total_output_file":
          return <div className="whitespace-nowrap">{cellValue ?? 0}</div>
        case "description":
          return (
            <RenameWorkflow
              defaultValue={cellValue}
              itemId={data.extraction_id}
              onValueChange={handleRenameWorkflow}
              isDisabled={status === STATUS_KEYS.DELETED}
            />
          )
        default:
          return <div className="whitespace-nowrap">{cellValue}</div>
      }
    },
    [],
  )

  const handleAutocompleteChange = (value: IAutocompleteData) => {
    updateModelFilters({
      keyword: value.name,
      attribute: value.type,
    })
  }

  const handleDateRangeChange = ({
    startDate,
    endDate,
  }: {
    startDate: string | Date | number | null
    endDate: string | Date | number | null
  }) => {
    let formattedEndDate = endDate
    if (endDate) {
      const date = new Date(endDate)
      date.setHours(23, 59, 59, 999)
  
      formattedEndDate = new CalendarDateTime(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds(),
      ).toString()
    }


    updateModelFilters({
      start_date: startDate,
      end_date: formattedEndDate,
    })
  }

  const handlePageChange = ({
    page,
    rowsPerPage,
    sort,
    direction,
  }: {
    page: number
    rowsPerPage: number
    sort: string | number
    direction: string
  }) => {
    updateModelFilters({
      page,
      items_per_page: rowsPerPage === 0 ? null : rowsPerPage,
      sort_field: sort,
      sort_direction: direction,
    })
  }

  const updateModelFilters = (updatedFields: Partial<typeof modelFilters>) => {
    setModelFilters(prev => ({
      ...prev,
      ...updatedFields,
    }))
  }

  const handleExport = async () => {
    if (rowsSelected.length <= 0) return

    try {
      const response =
        mode === ALL_FILES_MODE.WORKFLOWS
          ? await exportWorkflow(rowsSelected)
          : await exportAllFile(
              (currentData.data as IAllFilesResponse[]).reduce(
                (acc: any, item) => {
                  if (rowsSelected.includes(item.id)) {
                    item.process_type === "Input"
                      ? acc.input_ids.push(item.id)
                      : acc.output_ids.push(item.id)
                  }
                  return acc
                },
                { input_ids: [], output_ids: [] },
              ),
            )

      if (response) {
        FileSaver(response.data, response.fileName)
        setRowsSelected([])
        setResetSelection(prev => !prev)
      }
    } catch (error) {
      showError(handleError(error))
    }
  }

  const fetchAutocomplete = async (value: string = "") => {
    const effectiveMode = mode ?? ALL_FILES_MODE.WORKFLOWS
    if (!effectiveMode) return

    try {
      const isWorkflowMode = effectiveMode === ALL_FILES_MODE.WORKFLOWS
      const data = isWorkflowMode
        ? await getAutocomplete(value)
        : await getAutocompleteAllFile(value)
      setAutocompleteData(data)
    } catch {}
  }

  const getTotalQuantity = async () => {
    try {
      const data = await getQuantity()
      setQuantity(data)
    } catch {}
  }

  const summaryData = [
    { 
      count: quantity?.total_input_success ?? 0, 
      label: "Successful Txns", 
      tooltip: `${quantity?.total_input_success ?? 0} Successful Txns` 
    },
    { 
      count: quantity?.total_input_failed ?? 0, 
      label: "Failed Inputs", 
      tooltip: `${quantity?.total_input_failed ?? 0} Failed Inputs` 
    },
    {
      count: quantity?.total_input_file ?? 0,
      label: "Files Processed",
      tooltip: `${quantity?.total_input_file ?? 0} Files Processed`,
    },
    {
      count: quantity?.total_output_file ?? 0,
      label: "Output Files",
      tooltip: `${quantity?.total_output_file ?? 0} Output Files`,
    },
  ]

  const handleCheckboxChange = (isSelected: boolean) => {
    updateModelFilters({
      show_deleted: isSelected,
    })
  }

  const onConfirmDelete = async () => {
    try {
      const data = await deleteExtraction({ extraction_ids: rowsSelected })

      setRowsSelected([])
      setResetSelection(prev => !prev)
      showSuccess(data.message)
      fetchData(mode)
    } catch (error) {
      showError(handleError(error))
    }
  }

  const disableItems = () => {
    const entityId = mode === ALL_FILES_MODE.WORKFLOWS ? "extraction_id" : "id"

    const deletedItems = currentData.data
      .filter(item => item["status"] === STATUS_KEYS.DELETED)
      .map(item => item[entityId as keyof typeof item])

    return deletedItems
  }

  return (
    <div className="w-screen h-full flex flex-col px-11 py-8 relative before:absolute before:top-0 before:left-0 before:h-full before:w-full before:bg-cover before:bg-center before:bg-no-repeat before:bg-workflows-bg before:z-[1]">
      <FilterFiles
        mode={mode}
        rowsSelected={rowsSelected}
        autocompleteData={autocompleteData}
        onAutocompleteChange={handleAutocompleteChange}
        onDateRangeChange={handleDateRangeChange}
        onSearchChange={fetchAutocomplete}
        onExport={handleExport}
        onDelete={() => setIsOpenModal(prev => !prev)}
        onCheckboxChange={handleCheckboxChange}
        summaryComp={
          mode === ALL_FILES_MODE.FILES ? (
            <div className="flex items-center border-1 rounded bg-[#E8E8E8] bg-opacity-60 py-3 px-6 gap-5 h-12 ml-auto whitespace-nowrap max-w-[700px] min-w-[200px]">
              {summaryData.map((item, index) => (
                <Fragment key={index}>
                  <Tooltip showArrow={true} content={item.tooltip}>
                    <div className="text-sm items-center gap-1 text-[#6B6B6B] flex truncate">
                      <div className="text-lg font-bold truncate">
                        {item.count} <span className="text-sm font-normal">{item.label}</span>
                      </div>
                    </div>
                  </Tooltip>

                  {index < summaryData.length - 1 && <Divider orientation="vertical" />}
                </Fragment>
              ))}
            </div>
          ) : (
            <></>
          )
        }
      />

      <div className="h-[calc(100%-3rem-1.5rem)]">
        <TableCustom
          data={currentData.data}
          columns={currentColumns}
          renderCustomCell={customRenderCell}
          onPageChange={handlePageChange}
          isLoading={isLoading}
          onSelectRowChange={setRowsSelected}
          mode={mode}
          total={currentData.total_items}
          resetSelection={resetSelection}
          entityId={mode === ALL_FILES_MODE.WORKFLOWS ? "extraction_id" : "id"}
          disabledKeys={disableItems()}
        />
      </div>

      <ConfirmModal
        isOpenModal={isOpenModal}
        onOpenModalChange={setIsOpenModal}
        onConfirm={onConfirmDelete}
        title="Confirmation"
        content={
          <p>
            Deleting these workflows will permanently remove their content, but metadata and records
            will remain. This action is irreversible. Proceed?
          </p>
        }
        confirmButton={{ bgColor: "bg-error" }}
      />
    </div>
  )
}

export default AllFilesWrapper
