import { Button } from "@nextui-org/react"
import SearchableDropdown from "../SearchableDropdown/SearchableDropdown"
import { LIMIT_VERTICAL_LIST, STAGE_TITLE } from "@/constants/constants";

interface IProps {
  files: IFile[];
  onUploadNewFiles?: () => void;
  isAddButtonVisible?: boolean;
}
function FirstStepHeader(props: IProps) {
  const { files, onUploadNewFiles, isAddButtonVisible } = props


  const handleUploadClick = () => {
    if (onUploadNewFiles) {
      onUploadNewFiles();
    }
  };
  return (
    <div className="flex items-center text-black text-base font-semibold mb-6 shrink-0">
      <div className="mr-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
        </svg>
      </div>
      <span>{STAGE_TITLE.FIRST}</span>

      {isAddButtonVisible && (
        <Button 
        isIconOnly 
        variant="solid" 
        color="primary" 
        radius="full" 
        size="sm" 
        className="ml-2 h-6 w-6 min-w-6" 
        data-testid="add-button" 
        onClick={handleUploadClick}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
        </Button>
      )}

      <div className="ml-auto cursor-pointer">
        <SearchableDropdown items={files} title={files.length >= LIMIT_VERTICAL_LIST ? `Show ${files.length} files` : ''} />
      </div>
    </div>
  )
}

export default FirstStepHeader