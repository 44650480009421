import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Checkbox,
  Chip,
  DateRangePicker,
  RangeValue,
} from "@nextui-org/react"
import { ReactElement, useEffect, useRef, useState } from "react"
import { CalendarDate, getLocalTimeZone, today } from "@internationalized/date"

import FileExportIcon from "@assets/images/icons/file-export.svg"
import DeleteIcon from "@assets/images/icons/trash-bin.svg"
import closeIcon from "@assets/images/icons/close.svg"
import { formatToDateString } from "@/constants/common"
import useDebounce from "@/hooks/useDebounce/useDebounce"
import { ALL_FILES_MODE, AUTOCOMPLETE_TYPE, PERMISSIONS } from "@/constants/constants"
import usePermissions from "@/hooks/usePermissions/usePermissions"

interface IProps {
  summaryComp?: ReactElement
  onAutocompleteChange: (data: { name: string; type: string }) => void
  onDateRangeChange: (dateRange: { startDate: string | Date | number | null; endDate: string | Date | number | null}) => void
  autocompleteData: IAutocompleteData[]
  onExport: () => void
  rowsSelected: string[]
  onSearchChange: (value: string) => void
  onDelete: () => void
  onCheckboxChange: (isSelected: boolean) => void
  mode: string | null
}

const FilterFiles = (props: IProps) => {
  const {
    summaryComp,
    onAutocompleteChange,
    onDateRangeChange,
    autocompleteData,
    onExport,
    rowsSelected,
    onSearchChange,
    onDelete,
    onCheckboxChange,
    mode
  } = props

  const [searchValue, setSearchValue] = useState<string | number | null>("")
  const [dateRange, setDateRange] = useState<RangeValue<CalendarDate> | null>(
    {
      start: today(getLocalTimeZone()).subtract({ days: 30 }),
      end: today(getLocalTimeZone()),
    }
  )
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState("")
  const debouncedInputValue = useDebounce(inputValue, 300)
  const { hasPermission } = usePermissions()

  useEffect(() => {
    if (!searchValue) {
      onAutocompleteChange({ name: debouncedInputValue, type: "" })
    }

    onSearchChange(debouncedInputValue)
  }, [debouncedInputValue])

  useEffect(() => {
    onDateRangeChange({
      startDate: dateRange?.start ? formatToDateString(dateRange.start) : null,
      endDate: dateRange?.end ? formatToDateString(dateRange.end) : null,
    })
  }, [dateRange])

  const handleSelectionChange = (selectedValue: string | number | null) => {
    setSearchValue(selectedValue)
  }

  useEffect(() => {
    if (!searchValue) return

    const selectedItem = autocompleteData[Number(searchValue)]
    if (selectedItem) {
      onAutocompleteChange(selectedItem)
    }
  }, [debouncedInputValue, searchValue])

  const onDeleteExtraction = () => {
    onDelete()
  }

  const checkboxChange = (isSelected: boolean) => {
    onCheckboxChange(isSelected)
    setIsSelected(isSelected)
  }

  return (
    <div className="relative flex gap-2 z-10 items-center">
      <Autocomplete
        allowsCustomValue
        label="Search"
        variant="bordered"
        className="max-w-xs min-w-60"
        size="sm"
        color="default"
        selectedKey={searchValue}
        onSelectionChange={handleSelectionChange}
        onInputChange={setInputValue}
        inputProps={{
          classNames: {
            inputWrapper: "bg-white",
          },
        }}
        popoverProps={{
          className: "min-w-[500px]",
        }}
        listboxProps={{
          emptyContent: "No result found for your search.",
        }}
      >
        {autocompleteData.map((item, index) => (
          <AutocompleteItem
            key={index}
            value={item.name}
            endContent={
              <Chip radius="sm" className="bg-customGray-light text-white text-xs">
                {AUTOCOMPLETE_TYPE[item.type as keyof typeof AUTOCOMPLETE_TYPE] || item.type}
              </Chip>
            }
          >
            {item.name}
          </AutocompleteItem>
        ))}
      </Autocomplete>

      <DateRangePicker
        data-testid="date-range-picker"
        label="Date Range"
        className="max-w-xs"
        variant="bordered"
        size="sm"
        startContent={
          dateRange && (
            <Button
              className="order-3 -mr-2"
              variant="light"
              onPress={() => setDateRange(null)}
              isIconOnly
              radius="full"
              size="sm"
            >
              <img src={closeIcon} alt="close icon" className="size-4" />
            </Button>
          )
        }
        value={dateRange}
        onChange={setDateRange}
        classNames={{
          selectorButton: ["order-4"],
        }}
      />

      {mode && (
        <Checkbox 
          className="ml-4" 
          radius="sm" 
          isSelected={isSelected} 
          onValueChange={(isSelected: boolean) => checkboxChange(isSelected)}
        >
          Show deleted {mode === ALL_FILES_MODE.WORKFLOWS ? 'workflows' : 'files'}
        </Checkbox>
      )}

      {!!summaryComp && summaryComp}

      <div className="flex items-center gap-3 ml-auto">
        {
          hasPermission(PERMISSIONS.DELETE_EXTRACTION) && mode === ALL_FILES_MODE.WORKFLOWS && (
          <Button
            className="text-white bg-error"
            color="danger"
            size="lg"
            radius="sm"
            isDisabled={!rowsSelected.length}
            onPress={() => onDeleteExtraction()}
            startContent={<img src={DeleteIcon} alt="delete icon" />}
          >
            Delete
          </Button>
          )
        }

        <Button
          className="text-white ml-auto"
          color="primary"
          size="lg"
          radius="sm"
          isDisabled={!rowsSelected.length}
          onPress={() => onExport()}
          startContent={<img src={FileExportIcon} alt="export icon" />}
        >
          Export
        </Button>
      </div>
    </div>
  )
}

export default FilterFiles
