import { COOKIES } from '@/constants/cookies';
import { getLocalItem } from '@/helpers/storage';
import Axios, { AxiosError } from 'axios';

const axios = Axios.create({
  baseURL: process.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

axios.interceptors.request.use(
  (config) => {
    const token = getLocalItem(COOKIES.ACCESS_TOKEN_KEY);
    const organizationId = getLocalItem(COOKIES.ORGANIZATION_ID);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    if(organizationId) {
      config.headers["x-organization"] = `${organizationId}`;
    }
    return config;
  },
  (error: AxiosError | Error) => {
    return Promise.reject(error);
  },
);

export default axios;