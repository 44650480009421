import { Button, Input, Popover, PopoverContent, PopoverTrigger, Tooltip } from "@nextui-org/react"
import { useState } from "react"
import PencilIcon from "@assets/images/icons/pencil.svg"

interface IProps {
  defaultValue: string;
  onValueChange: Function;
  itemId: string;
  isDisabled: boolean
}

const RenameWorkflow = (props: IProps) => {
  const { defaultValue, onValueChange, itemId, isDisabled } = props
  const [wfName, setWfName] = useState("")
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenPopoverChange = (isOpen: boolean) => {
    setIsOpen(isOpen)
    if (isOpen) {
      setWfName(defaultValue)
    } else {
      onValueChange(wfName, itemId)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      setIsOpen(false);
      onValueChange(wfName, itemId)
    }
  };

  return (
    <div className="flex items-center gap-1 whitespace-nowrap">
      <Tooltip content={defaultValue} showArrow={true}>
        <span className="leading-none max-w-[200px] truncate">{defaultValue}</span>
      </Tooltip>
      <Popover
        placement="bottom"
        showArrow={true}
        isOpen={isOpen}
        classNames={{
          trigger: ["max-w-52", "whitespace-nowrap", "overflow-hidden", "text-ellipsis"],
        }}
        onOpenChange={handleOpenPopoverChange}
      >
        <PopoverTrigger>
          <Button isIconOnly aria-label="pencil" variant="light" size="sm" isDisabled={isDisabled}>
            <img src={PencilIcon} alt="pencil icon" data-testid="workflow-icon" className="size-4 cursor-pointer" />
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <div className="px-1 py-2">
            <div className="text-base mb-3">Rename workflow</div>
            <Input
              data-testid="rename-workflow-input"
              classNames={{
                mainWrapper: ["w-80", "min-w-40", "max-w-sm"],
              }}
              type="text"
              label=""
              variant="bordered"
              radius="sm"
              value={wfName}
              onChange={e => setWfName(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default RenameWorkflow
