import {
  Button,
  Pagination,
  Radio,
  RadioGroup,
  SortDescriptor,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react"
import { useCallback, useEffect, useMemo, useState } from "react"
import EmptyIcon from "@assets/images/icons/empty.svg"
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from "@/constants/constants"

interface IProps {
  data: IData[]
  columns: { name: string; uid: string; sortable?: boolean }[]
  renderCustomCell: (rowData: IData, columnKey: keyof typeof rowData) => React.ReactNode
  onPageChange: (params: {
    page: number
    rowsPerPage: number
    sort: string | number
    direction: string
  }) => void
  isLoading: boolean
  onSelectRowChange: Function
  mode: string | null
  total: number
  resetSelection: boolean
  entityId: string
  disabledKeys?: string[] | number[]
}

function TableCustom(props: IProps) {
  const {
    data,
    columns,
    renderCustomCell,
    onPageChange,
    isLoading,
    onSelectRowChange,
    mode,
    total,
    resetSelection,
    entityId,
    disabledKeys,
  } = props

  const [selectedKeys, setSelectedKeys] = useState<Set<string | number>>(new Set())
  const [page, setPage] = useState(1)
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>()
  const [rowPerPageOptions, setRowPerPageOptions] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS)
  const [rowsPerPage, setRowsPerPage] = useState(Number(DEFAULT_ROWS_PER_PAGE_OPTIONS[0].value))

  useEffect(() => {
    const selectedValues = Array.from(selectedKeys)
    onSelectRowChange(selectedValues)
  }, [selectedKeys])

  useEffect(() => {
    setRowPerPageOptions(prevOptions =>
      prevOptions.map(option =>
        option.name === "All" ? { ...option, value: total ? total.toString() : "0" } : option,
      ),
    )
  }, [total, rowsPerPage])

  useEffect(() => {
    const { column = "", direction = "" } = sortDescriptor || {}
    const sortDirection =
      direction === "ascending" ? "ASC" : direction === "descending" ? "DESC" : ""

    onPageChange({
      page,
      rowsPerPage,
      sort: column,
      direction: direction ? sortDirection : "",
    })
  }, [page, rowsPerPage, sortDescriptor])

  useEffect(() => {
    setSelectedKeys(new Set())
  }, [mode, resetSelection])

  const renderCell = useCallback(
    (rowData: IData, columnKey: keyof typeof rowData) => {
      if (renderCustomCell) {
        return renderCustomCell(rowData, columnKey)
      }

      const cellValue = rowData[columnKey]
      return <div>{cellValue}</div>
    },
    [renderCustomCell],
  )

  const pages = Math.ceil(total / rowsPerPage)

  const onPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const onNextPage = () => {
    if (page < pages) {
      setPage(page + 1)
    }
  }

  const onRowsPerPageChange = useCallback((value: string) => {
    setRowsPerPage(Number(value))
    setPage(1)
  }, [])

  const handleSelectionChange = useCallback(
    (keys: "all" | Set<string | number>) => {
      if (keys === "all") {
        const currentPageIds = new Set(data?.map(item => item[entityId as keyof typeof item]))
        setSelectedKeys(prev => new Set([...Array.from(prev), ...Array.from(currentPageIds)]))
      } else {
        setSelectedKeys(keys)
      }
    },
    [data],
  )

  const bottomContent = useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <div className="flex justify-between items-center">
          <RadioGroup
            label="Show results"
            orientation="horizontal"
            className="flex-row"
            classNames={{
              label: "h-fit",
              base: "items-center",
            }}
            onValueChange={onRowsPerPageChange}
          >
            {rowPerPageOptions.map(({ value, name }: { value: string; name: string }) => (
              <Radio
                key={name}
                classNames={{
                  wrapper: "hidden",
                  labelWrapper: [
                    rowsPerPage === Number(value)
                      ? "border-1 border-primary py-1 px-2 rounded "
                      : "",
                  ],
                  label: [rowsPerPage === Number(value) ? "text-primary" : ""],
                }}
                value={value}
              >
                {name}
              </Radio>
            ))}
          </RadioGroup>
        </div>
        <div className="hidden sm:flex max-w-[50%] justify-end items-center gap-2 ">
          <Button
            data-testid="prev-button"
            isDisabled={pages === 1}
            size="sm"
            variant="light"
            onPress={onPreviousPage}
          >
            Prev
          </Button>
          <Pagination showShadow color="primary" page={page} total={pages} onChange={setPage} />
          <Button isDisabled={pages === 1} size="sm" variant="light" onPress={onNextPage}>
            Next
          </Button>
        </div>
      </div>
    )
  }, [selectedKeys, page, pages, rowsPerPage, rowPerPageOptions])

  return (
    <Table
      className="z-10 mt-6 h-full"
      classNames={{
        base: "h-full",
        wrapper: "h-100 p-0",
        sortIcon: "opacity-100",
        th: "text-sm font-normal",
        tr: "border-b-1 border-[#B0B0B0]",
        td: "p-3.5",
        thead: "[&>tr:nth-child(2)]:hidden"
      }}
      aria-label="table with custom cells"
      selectionMode="multiple"
      isHeaderSticky
      bottomContent={data.length ? bottomContent : undefined}
      bottomContentPlacement="outside"
      selectedKeys={selectedKeys}
      onSelectionChange={handleSelectionChange}
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
      disabledKeys={[]}
    >
      <TableHeader columns={columns}>
        {column => (
          <TableColumn
            key={column.uid}
            allowsSorting={column.sortable}
            align={column.uid === "actions" ? "center" : "start"}
          >
            {column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        items={data}
        isLoading={isLoading}
        loadingContent={<Spinner />}
        emptyContent={
          <div className="flex flex-col items-center justify-center">
            <img src={EmptyIcon} alt="empty icon" />
            <p className="text-[#729A9B]">No Result found for your search</p>
          </div>
        }
      >
        {item => (
          <TableRow
            key={item[entityId as keyof typeof item]}
            className={`${disabledKeys?.length && disabledKeys.includes(item[entityId as never]) ? "bg-[#EAEAEA] opacity-70" : ""}`}
          >
            {columnKey => <TableCell>{renderCell(item, columnKey as keyof typeof item)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

export default TableCustom
