import * as React from 'react';
import { LoadError, Plugin, SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import type { PdfJs } from '@react-pdf-viewer/core';
import { PdfToolbar } from '../PdfToolbar/PdfToolbar';
import HighlightPluginInstance from '../PdfHighLight/PdfHighLight';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import { CustomPdfProps } from '~@types/pdf-file-keywords';
import { Spinner } from '@nextui-org/react';
import { rotatePlugin } from '@react-pdf-viewer/rotate';

const CustomPdf: React.FC<CustomPdfProps> = ({
  fileUrl,
  fileName,
  isSearchType,
  isClosePdf,
  defaultScale,
  renderError,
  isShowThumbnail,
  highlightAreas,
  hoveredIndex
}) => {
  const [loadFileFail, setLoadFileFail] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const scale = (val: number | SpecialZoomLevel | undefined) => val !== undefined ? val : 1.25;
  const highlightPluginInstance = HighlightPluginInstance(highlightAreas, hoveredIndex);
  const toolbarPluginInstance = toolbarPlugin({
    fullScreenPlugin: {
      onEnterFullScreen(zoom) {
        zoom(scale(defaultScale))
      },
      onExitFullScreen(zoom) {
        zoom(scale(defaultScale))
      },
    }
  });
  const { Toolbar } = toolbarPluginInstance;

  const thumbnailPluginInstance = thumbnailPlugin({
    thumbnailWidth: 150,
    renderSpinner: () => <Spinner size='lg' />
  });

  const { Thumbnails } = thumbnailPluginInstance;
  const rotatePluginInstance = rotatePlugin();

  const plugins: Plugin[] = [thumbnailPluginInstance, highlightPluginInstance, toolbarPluginInstance, rotatePluginInstance];

  const handleRenderError = React.useCallback((error: LoadError) => {
    setLoadFileFail(true);
    setErrorMessage(error.message || '');
    if (renderError === undefined) {
      return <></>;
    } else {
      return renderError(error);
    }
  }, [renderError]);

  React.useEffect(() => {
    if (!fileUrl || !fileUrl.trim()) {
      handleRenderError({ message: 'No file URL provided' });
    }
  }, [fileUrl, handleRenderError]);

  const renderPDFViewer = () => {
    if (loadFileFail) {
      return <div data-testid="error-message">{errorMessage}</div>;
    }

    return (<>
      {(!loadFileFail && !!fileUrl && !!fileUrl.trim()) && (<div className="flex items-center px-3 py-2 h-11 border-b-1 border-b-customGray-light">
        <PdfToolbar
          Toolbar={Toolbar}
          fileUrl={fileUrl}
          fileName={fileName}
          isClosePdf={isClosePdf}
          isSearchType={isSearchType}
          searchPluginInstance={toolbarPluginInstance.searchPluginInstance}
          rotatePluginInstance={rotatePluginInstance}
        />
      </div>)}
      <div className="h-[calc(100%-2.75rem)]" style={!!loadFileFail || !fileUrl || !fileUrl.trim() ? { borderColor: 'transparent', height: "65%" } : {}}>
        {(!loadFileFail && !!fileUrl && !!fileUrl.trim() && isShowThumbnail) && (<div className="lib-rpv-sidebar">
          <Thumbnails />
        </div>)}
        <div className="h-full" style={{ flex: 1 }}>
          {(!!fileUrl && !!fileUrl.trim()) ? (<Viewer
            transformGetDocumentParams={(options: PdfJs.GetDocumentParams) =>
              Object.assign({}, options, {
                isEvalSupported: false,
              })
            }
            fileUrl={fileUrl}
            renderError={(error: LoadError) => {
              setLoadFileFail(true);
              if (renderError === undefined) {
                return <></>
              } else return renderError(error);
            }}
            renderLoader={(percentages: number) => (
              <Spinner size="lg" />
            )}
            defaultScale={scale(defaultScale)}
            plugins={plugins}
          />) :
            <></>
          }
        </div>
      </div>
    </>);
  }

  return (
    <>
      <div className="h-full">
        {renderPDFViewer()}
      </div>
    </>
  );

};

export default CustomPdf;
